<template>
    <div class="page-msds g-page">
        <div class="wp">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path:'/'}">首页</el-breadcrumb-item>
                <el-breadcrumb-item>MSDS/COA</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="msds">
                <div class="row">
                    <div class="con">
                        <el-select v-model="MSDSinfo.brandid" placeholder="选品牌">
                            <el-option
                                v-for="item in brandList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="con">
                        <el-input v-model="MSDSinfo.goodsNumber" placeholder="请输入产品编号"></el-input>
                    </div>
                    <div class="btn" @click="getgoodsmsdsList">查询MSDS</div>
                </div>
                <div class="row">
                    <div class="con">
                        <el-select v-model="COAinfo.brandid" placeholder="选品牌">
                            <el-option
                                v-for="item in brandList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="con">
                        <el-input v-model="COAinfo.goodsNumber" placeholder="请输入产品编号"></el-input>
                    </div>
                    <div class="con">
                        <el-input v-model="COAinfo.casNumber" placeholder="请输入批号"></el-input>
                    </div>
                    <div class="btn" @click="getgoodscoaList">查询COA</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {goodscoaList, goodsmsdsList} from "@/api/Product";
import {brandinfoList} from "@/api/home";

export default {
    name: "MSDSCOA",
    data() {
        return {
            MSDSinfo:{
                brandid:"",// 品牌id
                goodsNumber:"", // 产品编号
            },
            COAinfo:{
                brandid:"",// 品牌id
                goodsNumber:"", // 产品编号
                casNumber:"", // 批号
            },
            brandList:[], //品牌列表
            COAbrand:"",
            MSDSbrand:"",
        }
    },
    mounted() {
        this.getbrandinfoList() //获取全部品牌
    },
    methods:{

        // ——————————————————————————接口请求
        // 查询msds下载文件
        getgoodsmsdsList(){
            if(!this.MSDSinfo.brandid){
                this.$message({
                    type: 'warning',
                    message: "请选择品牌！",
                    duration: 2000
                });
                return
            }
            if(!this.MSDSinfo.goodsNumber){
                this.$message({
                    type: 'warning',
                    message: "请输入产品编号！",
                    duration: 2000
                });
                return
            }

            let param={
                brandId:this.MSDSinfo.brandid, // 品牌id
                goodsNumber:this.MSDSinfo.goodsNumber, // 产品编号
            }
            goodsmsdsList(param).then((res) => {
                if (res.data.code==200) {
                    if(res.data.data.records.length>0){
                        window.open(res.data.data.records[0].fileUrl)
                    }else{
                        this.$message({
                            type: 'warning',
                            message: "未查询到相关文件",
                            duration: 2000
                        });
                    }
                }else{
                    this.$message({
                        type: 'error',
                        message:res.data.message,
                        duration: 2000
                    });
                }
            })
        },
        // 查询coa下载文件
        getgoodscoaList(){
            if(!this.COAinfo.brandid){
                this.$message({
                    type: 'warning',
                    message: "请选择品牌！",
                    duration: 2000
                });
                return
            }
            if(!this.COAinfo.goodsNumber){
                this.$message({
                    type: 'warning',
                    message: "请输入产品编号！",
                    duration: 2000
                });
                return
            }
            if(!this.COAinfo.casNumber){
                this.$message({
                    type: 'warning',
                    message: "请输入批号！",
                    duration: 2000
                });
                return
            }

            let param={
                batchNumber:this.COAinfo.casNumber, // 批号
                brandId:this.COAinfo.brandid, // 品牌id
                goodsNumber:this.COAinfo.goodsNumber, // 产品编号
            }
            goodscoaList(param).then((res) => {
                if (res.data.code==200) {
                    if(res.data.data.records.length>0 && res.data.data.records[0].fileUrl){
                        window.open(res.data.data.records[0].fileUrl)
                    }else{
                        this.$message({
                            type: 'warning',
                            message: "未查询到相关文件",
                            duration: 2000
                        });
                    }
                }else{
                    this.$message({
                        type: 'error',
                        message:res.data.message,
                        duration: 2000
                    });
                }
            })
        },

        // 获取全部品牌
        getbrandinfoList(){
            brandinfoList('').then((res) => {
                if (res.data.code==200) {
                    this.brandList=res.data.data
                }else{

                }
            })
        },

    },
}
</script>

<style scoped>

</style>
